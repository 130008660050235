body {
  overflow-x: hidden;
}

.header {
  background-color: #009444 !important;
  /* margin-top: 50px; */
}

.header-top .header-links {
  list-style: none;
  display: flex;
  justify-content: end;
  position: relative;
  z-index: 10;
}

.header-top .header-links .nav-item a {
  color: white !important;
}

.navbar {
  height: 60px;
}

.navbar-nav .nav-item a:hover::before {
  width: calc(100% - 16px);
  transition: all 0.3s;
}
.navbar-nav .nav-item a::before {
  position: absolute;
  content: "";
  width: 0%;
  height: 2px;
  background-color: white;
  bottom: -2px;
  /* left: 0; */
  transition: all 0.3s;
}
.navbar-nav .nav-item a {
  position: relative;
  color: white !important;
  padding: 0px 50px;
  cursor: pointer;
}

.navbar-nav .nav-item {
  padding: 0px 10px;
}

/* CSS  -  C H A N G E S */

.section-1 .row1 {
  padding: 150px 0px;
}
.section-1 .text {
  max-width: 480px;
}

.section-1 .roadmap {
  /* background-image: linear-gradient(90deg, rgb(254, 254, 254) 20%, rgba(203, 196, 196, 0.114) 100%), url(./assets/img/BG.png); */
  /* background-image: url(./assets/img/roadmap.png);
  background-size: cover; */
  /* background-position: center; */
  position: relative;
}

.section-1 .roadmap img {
  position: absolute;
  bottom: 0;
  right: 120px;
}

.section-1 .roadmap .point_1 img {
  position: absolute;
  bottom: 130px;
  left: -150px;
}

.section-1 .roadmap .point_2 img {
  position: absolute;
  bottom: 130px;
  left: 360px;
}

.section-1 .roadmap .point_3 img {
  position: absolute;
  bottom: 250px;
  left: 100px;
}

.section-1 .roadmap .point_4 img {
  position: absolute;
  top: 145px;
  left: 500px;
}

.section-1 .roadmap .point_5 img {
  position: absolute;
  top: 130px;
  left: 150px;
}

/* CSS  -  C H A N G E S  E N D */

.section-1 .row1 {
  padding: 150px 0px;
}

.section-1 h1 {
  font-size: 80px;
  font-weight: 700;
}

.section-1 h1 span {
  color: #009444;
}

.section-1-end {
  background-color: #009444;
}

.section-1-end h4 {
  color: white;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 2px;
}

.section_2 {
  background-image: url(./assets/img/sec2BG.png);
}

.section_2 .head {
  max-width: 950px;
  padding-top: 120px;
  margin: auto;
}

.head h1 {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.section_2 .head h1 span {
  color: #009444;
}

.section_2 .head p {
  color: black;
}

.img-2-text {
  padding-top: 60px;
}

.img-2-text h1 {
  font-size: 40px;
  font-weight: 700;
  padding-bottom: 15px;
  position: relative;
}

.img-2-text h1::before {
  content: "";
  position: absolute;
  background-color: #009444;
  height: 5px !important;
  width: 10%;
  bottom: 0;
}

.img-2-text .btn {
  background: linear-gradient(270deg, #009444 0, #009444) 0 100% transparent
    no-repeat;
  background-size: 100% 100%;
  border: 2px solid #009444;
  transition: all 0.3s;
  color: #fff;
  font-weight: 700;
  padding: 12px 40px;
  position: relative;
}
.img-2-text .btn:hover {
  color: #009444;
  background-size: 0% 100%;
  transition: all 0.3s;
}

.section_2 .card {
  padding: 40px;
  border-radius: 20px;
  background-color: #009444;
  color: white;
}

.section_2 .content {
  max-width: 500px;
  margin: auto;
}

.section_2 .card img,
h3,
p {
  margin-bottom: 2rem;
}

.section_2 .card .btn:hover {
  background-color: #009444;
  color: #fff;
  font-weight: 700;
  border: 2px solid #fff;
  transition: all 0.3s;
}
.section_2 .card .btn {
  background-color: white;
  color: #009444;
  font-weight: 700;
  border: 2px solid #009444;
  transition: all 0.3s;
}

.section_2 #more_wz {
  display: none;
}

.section-3 {
  margin-top: 100px;
  padding: 40px 0px;
  background-color: #009444;
  color: white;
}

.section-3 h2 {
  margin-bottom: 1.5rem;
}

.section-4 {
  padding-top: 120px;
}

.section-4 .head {
  padding-bottom: 50px;
}

.section-4 .image1 {
  background-image: url(./assets/img/team1.jpg);
  background-size: cover !important;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 10px !important;
  /* box-shadow: 2px 2px 16px rgba(169, 176, 214, .25); */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 280px;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
}

.section-4 .image2 {
  background-image: url(./assets/img/team2.jpg);
  background-size: cover !important;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 10px !important;
  /* box-shadow: 2px 2px 16px rgba(169, 176, 214, .25); */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 280px;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
}

.section-4 .image3 {
  background-image: url(./assets/img/team3.jpg);
  background-size: cover !important;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 10px !important;
  /* box-shadow: 2px 2px 16px rgba(169, 176, 214, .25); */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 280px;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
}

.section-4 .image4 {
  background-image: url(./assets/img/team4.jpg);
  background-size: cover !important;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 10px !important;
  /* box-shadow: 2px 2px 16px rgba(169, 176, 214, .25); */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 280px;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
}

.section-4 .image5 {
  background-image: url(./assets/img/team5.jpg);
  background-size: cover !important;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 10px !important;
  /* box-shadow: 2px 2px 16px rgba(169, 176, 214, .25); */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 280px;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
}

.section-4 .image6 {
  background-image: url(./assets/img/team6.jpg);
  background-size: cover !important;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 10px !important;
  /* box-shadow: 2px 2px 16px rgba(169, 176, 214, .25); */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 280px;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
}

.section-4 .imagesheh {
  background-image: url(./assets/img/Qumber.png);
  background-size: cover !important;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 10px !important;
  /* box-shadow: 2px 2px 16px rgba(169, 176, 214, .25); */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 280px;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
}

.section-4 .image7 {
  background-image: url(./assets/img/Naimat.jpg);
  background-size: cover !important;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 10px !important;
  /* box-shadow: 2px 2px 16px rgba(169, 176, 214, .25); */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 280px;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
}

.section-4 .image8 {
  background-image: url(./assets/img/team8.jpg);
  background-size: cover !important;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 10px !important;
  /* box-shadow: 2px 2px 16px rgba(169, 176, 214, .25); */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 280px;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
}

.section-4 .content {
  /* -webkit-transform: translateY(40%); */
  transform: translateY(30%);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  background: rgba(0, 0, 0, 0.664);
  border-radius: 0px 0px 7px 7px;
  color: #fff !important;
  font-size: 12px;
  padding: 20px 23.5px 25px;
}

.section-4 .content h5 {
  font-size: 16px;
}

.contact-form {
  background-color: #009444;
  color: white;
  padding: 50px;
  border-radius: 30px;
  /* max-width: 900px; */
  margin: 0px auto 80px auto;
}

.contact-form h5 {
  text-align: left;
  margin-bottom: 2rem;
  font-weight: 700;
}

.contact-form .form-group {
  text-align: left !important;
}

.contact-form .form-group label {
  font-weight: 500;
}

.contact-form .btn:hover {
  background-color: #009444;
  color: #fff;
  border: 2px solid #fff;
  transition: all 0.3s;
}
.contact-form .btn {
  background-color: white;
  color: #009444;
  font-weight: 700;
  padding: 10px 40px;
  border: 2px solid #009444;
}

.footer {
  background-color: #333333;
  color: white;
  padding: 50px 0px 0px 0px;
}

.footer h5 {
  margin-bottom: 2rem;
}

.footer p {
  margin-bottom: 1rem;
}

.footer .btn:hover {
  background-size: 0% 100%;
  transition: all 0.3s;
}
.footer .btn {
  background: linear-gradient(270deg, #009444 0, #009444) 0 100% transparent
    no-repeat;
  background-size: 100% 100%;
  border: 2px solid #009444;
  transition: all 0.3s;
  color: white;
  padding: 10px 40px;
}

.footer .coyright {
  border-top: 1px solid gray;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer .coyright small {
}

.mobileSec {
  padding: 60px 0;
  color: black;
  background: url(./assets/img/sec2BG.png);
}
.ai-center {
  align-items: center;
}
.mobileSec h1 {
  font-size: 50px;
  margin-bottom: 40px;
  font-weight: 700;
  text-align: center;
}
.mobileSec .imgDiv img {
  position: relative;
}
.mobileSec .content p {
  font-size: 18px;
  margin-bottom: 30px;
}
.mobileSec .content h2 {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 30px;
}
.mobileSec .content .store {
  display: flex;
  align-items: center;
  max-width: 400px;
}
.mobileSec .content .store a {
  width: 100%;
  display: inline-block;
}
.mobileSec .content .store a:first-child {
  margin-right: 20px;
}
.mobileSec .imgDiv {
  position: relative;
  text-align: center;
}
.mobileSec .imgDiv .mobile1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -2;
}
.mobileSec .imgDiv .mobile3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.font-bold {
  font-weight: bold;
}
.modal {
  backdrop-filter: blur(5px);
}

@media screen and (max-width: 1400px) {
  .section-1 .roadmap .point_1 img {
    position: absolute;
    bottom: 150px;
    left: -100px;
    width: 190px;
  }
  .section-1 .roadmap .point_2 img {
    position: absolute;
    bottom: 130px;
    left: 280px;
    width: 190px;
  }
  .section-1 .roadmap .point_3 img {
    position: absolute;
    bottom: 250px;
    left: 110px;
    width: 190px;
  }

  .section-1 .roadmap .point_4 img {
    position: absolute;
    top: 205px;
    left: 430px;
    width: 160px;
  }

  .section-1 .roadmap .point_5 img {
    position: absolute;
    top: 180px;
    left: 135px;
    width: 190px;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .section-1 .row1 {
    padding-bottom: 0px;
  }
  .section-1 .roadmap img {
    position: absolute;
    bottom: 0px;
    top: 178px;
    right: 0px;
  }
  .section-1 .roadmap .point_1 img {
    position: absolute;
    top: 300px;
    left: 10px;
    width: 155px;
  }

  .section-1 .roadmap .point_2 img {
    position: absolute;
    top: 350px;
    left: 340px;
    width: 155px;
  }

  .section-1 .roadmap .point_3 img {
    position: absolute;
    top: 220px;
    left: 190px;
    width: 155px;
  }

  .section-1 .roadmap .point_4 img {
    position: absolute;
    top: 180px;
    left: 400px;
    width: 105px;
  }

  .section-1 .roadmap .point_5 img {
    position: absolute;
    top: 130px;
    left: 220px;
    width: 155px;
  }
}

@media screen and (max-width: 1024px) {
  .section-1 h1 {
    font-size: 60px;
    font-weight: 700;
  }
  .section-1 .roadmap img {
    position: absolute;
    bottom: 0px;
    top: 178px;
    right: 0px;
  }
  .section-1 .roadmap .point_1 img {
    position: absolute;
    top: 300px;
    left: 10px;
    width: 155px;
  }

  .section-1 .roadmap .point_2 img {
    position: absolute;
    top: 330px;
    left: 330px;
    width: 155px;
  }

  .section-1 .roadmap .point_3 img {
    position: absolute;
    top: 220px;
    left: 190px;
    width: 155px;
  }

  .section-1 .roadmap .point_4 img {
    position: absolute;
    top: 180px;
    left: 390px;
    width: 105px;
  }

  .section-1 .roadmap .point_5 img {
    position: absolute;
    top: 130px;
    left: 220px;
    width: 155px;
  }

  /* .section-1 .roadmap .point_1 img {
    position: absolute;
    bottom: 100px;
    left: -155px;
    width: 190px;
  }

  .section-1 .roadmap .point_2 img {
    position: absolute;
    bottom: 80px;
    left: 230px;
    width: 190px;
  }

  .section-1 .roadmap .point_3 img {
    position: absolute;
    bottom: 190px;
    left: 30px;
    width: 190px;
  }

  .section-1 .roadmap .point_4 img {
    position: absolute;
    top: 195px;
    left: 330px;
    width: 160px;
  }

  .section-1 .roadmap .point_5 img {
    position: absolute;
    top: 180px;
    left: 55px;
    width: 190px;
  } */
}

@media screen and (max-width: 992px) {
  .navbar {
    height: 100%;
  }

  .navbar-nav .nav-item a {
    color: white !important;
    padding: 10px 0px;
  }
  .section-1 .row1 {
    padding: 50px 10px 30px 10px;
  }
  .section-1 .roadmap {
    height: 450px;
  }
  .section-1 .roadmap img {
    top: 0px;
  }
  .section-1 .roadmap .point_5 img {
    position: absolute;
    top: -50px;
    right: 160px;
    left: unset;
    width: 150px;
  }
  .section-1 .roadmap .point_4 img {
    position: absolute;
    top: 5px;
    right: -20px;
    left: unset;
    width: 110px;
  }
  .section-1 .roadmap .point_3 img {
    position: absolute;
    top: 70px;
    right: 200px;
    left: unset;
    width: 150px;
  }
  .section-1 .roadmap .point_2 img {
    position: absolute;
    top: 225px;
    right: 65px;
    left: unset;
    width: 150px;
  }
  .section-1 .roadmap .point_1 img {
    position: absolute;
    top: 200px;
    right: 465px;
    left: unset;
    width: 150px;
  }
  .section-1 h1 {
    font-size: 60px;
    font-weight: 700;
    text-align: center;
  }
  .section-1 p {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .section-4 .image1 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .section-4 .image2 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .section-4 .image3 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .section-4 .image4 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .section-4 .image5 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .section-4 .image6 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .section-4 .imagesheh {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media screen and (width: 767px) {
  .row_flex {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 767px) {
  .row_flex {
    display: flex;
    flex-direction: column-reverse;
  }
  .mobileSec .content h2 {
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  /* .row_flex {
    display: flex;
    flex-direction: column-reverse;
  } */

  .img-2-text img {
    margin-bottom: 2rem;
  }

  .section-1 .roadmap .point_5 img {
    position: absolute;
    top: -35px;
    right: 125px;
    left: unset;
    width: 120px;
  }
  .section-1 .roadmap .point_4 img {
    position: absolute;
    top: 5px;
    right: -20px;
    left: unset;
    width: 90px;
  }
  .section-1 .roadmap .point_3 img {
    position: absolute;
    top: 55px;
    right: 150px;
    left: unset;
    width: 120px;
  }
  .section-1 .roadmap .point_2 img {
    position: absolute;
    top: 170px;
    right: 20px;
    left: unset;
    width: 140px;
  }
  .section-1 .roadmap .point_1 img {
    position: absolute;
    top: 150px;
    /* right: 355px; */
    left: 10%;
    width: 120px;
  }
  .section_2 .head {
    padding-top: 80px;
  }

  .section-4 .image1 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .section-4 .image2 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .section-4 .image3 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .section-4 .image4 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .section-4 .image5 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .section-4 .image6 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .section-4 .imagesheh {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 576px) {
  .section-1 h1 {
    font-size: 50px;
    font-weight: 700;
  }
  .section-1 .roadmap .point_4 img {
    position: absolute;
    top: 64px;
    right: 6px;
    left: unset;
    width: 81px;
  }
  .section-1 .roadmap .point_2 img {
    position: absolute;
    top: 162px;
    right: 20px;
    left: unset;
    width: 140px;
  }
}
@media screen and (max-width: 500px) and (min-width: 450px) {
  .section-1 .roadmap {
    max-width: 440px;
    margin: auto;
  }
  .section-1 .roadmap .point_4 img {
    top: -1px;
    right: 0px;
  }
  .section-1 .roadmap .point_2 img {
    top: 122px;
    right: 20px;
  }
  .section-1 .roadmap .point_1 img {
    left: 7%;
    top: 142px;
  }
  .section-1 .roadmap .point_3 img {
    top: 55px;
    right: 128px;
  }
}
@media screen and (max-width: 450px) and (min-width: 400px) {
  .section-1 .roadmap {
    max-width: 390px;
    margin: auto;
  }
  .section-1 .roadmap .point_4 img {
    top: -6px;
    right: -6px;
  }
  .section-1 .roadmap .point_2 img {
    top: 106px;
    right: 20px;
  }
  .section-1 .roadmap .point_1 img {
    left: 7%;
    top: 155px;
  }
  .section-1 .roadmap .point_3 img {
    top: 55px;
    right: 128px;
  }
}
@media screen and (max-width: 400px) and (min-width: 350px) {
  .section-1 .roadmap {
    max-width: 340px;
    margin: auto;
  }
  .section-1 .roadmap .point_4 img {
    top: 46px;
    right: 0px;
    width: 76px;
  }
  .section-1 .roadmap .point_2 img {
    top: 149px;
    right: 10px;
  }
  .section-1 .roadmap .point_1 img {
    left: 3%;
    top: 130px;
    width: 110px;
  }
  .section-1 .roadmap .point_3 img {
    top: 55px;
    right: 98px;
    width: 105px;
  }
  .section-1 .roadmap .point_5 img {
    right: 80px;
    width: 100px;
    top: -5px;
  }
}
@media screen and (max-width: 350px) {
  .section-1 .roadmap {
    max-width: 300px;
    margin: auto;
  }
  .section-1 .roadmap .point_4 img {
    top: 46px;
    right: 0px;
    width: 70px;
  }
  .section-1 .roadmap .point_2 img {
    top: 129px;
    right: 10px;
    width: 125px;
  }
  .section-1 .roadmap .point_1 img {
    left: 2%;
    top: 135px;
    width: 110px;
  }
  .section-1 .roadmap .point_3 img {
    top: 55px;
    right: 86px;
    width: 105px;
  }
  .section-1 .roadmap .point_5 img {
    right: 70px;
    width: 100px;
  }
}
@media screen and (max-width: 375px) {
  .section-1 .row1 {
    padding: 110px 0px;
  }

  .head h1 {
    font-size: 36px;
  }
  .mobileSec h1 {
    font-size: 36px;
  }
}
